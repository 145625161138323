import React from "react";
import { FilterLiveSearch, FilterList, FilterListItem } from "react-admin";
import { Card, CardContent } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";

export const TagsFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
    <CardContent>
      <FilterLiveSearch />
      <FilterList label="category" icon={<DashboardIcon />}>
        <FilterListItem label="career" value={{ category: "career" }} />
        <FilterListItem label="overseas" value={{ category: "overseas" }} />
        <FilterListItem label="business" value={{ category: "business" }} />
        <FilterListItem label="finance" value={{ category: "finance" }} />
        <FilterListItem label="travel" value={{ category: "travel" }} />
        <FilterListItem label="other" value={{ category: "other" }} />
      </FilterList>
    </CardContent>
  </Card>
);
