import React, { useEffect } from "react";
import { Admin, AppBar, Layout, Resource, UserMenu } from "react-admin";
import { UserList, UserCreate, UserEdit } from "./components/users";
import simpleRestProvider from "ra-data-simple-rest";
import { FirebaseAuthProvider } from "react-admin-firebase";
import { LocalStorageKeys } from "./storage";
import { customFetchJson } from "./customFetch";
import MyLogoutButton from "./MyLogoutButton";
import { TagCreate, TagEdit, TagList } from "./components/tags";
import { PendingApprovalList } from "./components/pending_approve";
import { ForumEdit, ForumList } from "./components/forum";

const MyUserMenu = () => (
  <UserMenu>
    <MyLogoutButton />
  </UserMenu>
);

const MyAppBar = () => <AppBar userMenu={<MyUserMenu />} />;

const MyLayout = (props: any) => <Layout {...props} appBar={MyAppBar} />;

//connect the data provider to the REST endpoint
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const options = {
  // Use a different root document to set your resource collections, by default it uses the root collections of firestore
  // rootRef: 'root-collection/some-doc',
  // Your own, previously initialized firebase app instance
  //     app: firebaseAppInstance,
  // Enable logging of react-admin-firebase
  logging: false,
  // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
  //     watch: ['posts'],
  // Resources you explicitly dont want realtime updates for
  //     dontwatch: ['comments'],
};

const httpClient = async (url: string, options = {} as any) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (!localStorage.getItem(LocalStorageKeys.token)) {
    return setNewToken(url, options);
  } else {
    try {
      const key = JSON.parse(
        localStorage.getItem(LocalStorageKeys.token) as string
      );
      const { token, expired } = key;
      if (expired < Date.now()) {
        return setNewToken(url, options);
      } else if (token) {
        options.headers.set("Authorization", `Bearer ${token}`);
        return customFetchJson(url, options);
      } else {
        return firebaseLogout(url, options);
      }
    } catch {
      return firebaseLogout(url, options);
    }
  }
};

const setNewToken = (url: string, options: any) => {
  const auth = firebaseAuthProvider.getAuthUser();
  return auth
    .then(async (user) => {
      const email = user.email;
      const adminToken = await user.getIdToken();
      if (!email) {
        throw new Error("No email");
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/system/gtoken`,
          {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
        const data = await res.json();
        const token = data.idToken;
        const customToken = {
          token,
          expired: Date.now() + data.expiresIn * 1000,
        };
        localStorage.setItem(
          LocalStorageKeys.token,
          JSON.stringify(customToken)
        );
        options.headers.set("Authorization", `Bearer ${token}`);
        return customFetchJson(url, options);
      } catch {
        return firebaseLogout(url, options);
      }
    })
    .catch(() => {
      return customFetchJson(url, options);
    });
};

//connect the data provider to the REST endpoint
const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL as string,
  httpClient
);

const firebaseAuthProvider = FirebaseAuthProvider(config, options);

const firebaseLogout = (url: string, options: any) => {
  localStorage.removeItem(LocalStorageKeys.token);
  firebaseAuthProvider.logout("login");
  return customFetchJson(url, options);
};

function App() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hostname === 'sallyer-develop-auth.web.app' || window.location.hostname === 'sallyer-develop-auth.firebaseapp.com') {
        window.location.replace('https://admin-develop.sallyer.com');
      }
      if (window.location.hostname === 'sallyer-auth.web.app' || window.location.hostname === 'sallyer-auth.firebaseapp.com') {
        window.location.replace('https://admin.sallyer.com');
      }
    }
  }, []);
  return (
    <Admin
      layout={MyLayout}
      dataProvider={dataProvider}
      authProvider={firebaseAuthProvider}
    >
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource name="forum" list={ForumList} edit={ForumEdit} />
      <Resource name="tags" list={TagList} create={TagCreate} edit={TagEdit} />
      <Resource name="users/pending_approval" list={PendingApprovalList} />
    </Admin>
  );
}

export default App;
