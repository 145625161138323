import * as React from "react";
import {
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  useRecordContext,
} from "react-admin";

const CustomToolbar = (props) => {
  const record = useRecordContext();
  const { page } = props;
  if (page === "tagEdit" && record.linked_uids?.length > 0) {
    return (
      <Toolbar
        {...props}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <SaveButton />
        <DeleteWithConfirmButton disabled />
      </Toolbar>
    );
  } else {
    return (
      <Toolbar
        {...props}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <SaveButton />
        <DeleteWithConfirmButton />
      </Toolbar>
    );
  }
};

export default CustomToolbar;
