import React from "react";
import { ImageField, useInput, useRecordContext } from "react-admin";

const ImagePreview = ({ source }: any) => {
  const { field } = useInput({ source });
  const record = useRecordContext();

  let imgURL = record ? record[source] : "";
  if (field.value) imgURL = field.value;

  return imgURL ? (
    <img
      src={`${imgURL}`}
      style={{ width: "auto", height: 100 }}
      referrerPolicy="no-referrer"
    />
  ) : null;
};

export default ImagePreview;
