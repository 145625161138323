import React, { useEffect } from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  Edit,
  BooleanField,
  useRecordContext,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  NumberField,
} from "react-admin";
import MyPagination from "./MyPagination";
import { TagsFilterSidebar } from "./TagsFilterSidebar";
import CustomToolbar from "../CustomToolbar";
import { mydiv, myspan } from "../layout";
import { useWatch, useFormContext } from "react-hook-form";

const TranslationStatusField = ({ source }: any) => {
  const record = useRecordContext();

  const hasEnglishTranslation = record[source]?.en;
  const hasChineseTranslation = record[source]?.["zh-TW"];
  const isTranslated =
    hasEnglishTranslation && hasChineseTranslation ? true : false;
  return (
    <BooleanField
      source={source}
      record={{ ...record, [source]: isTranslated }}
    />
  );
};

const LinkedUidsCountField = ({ source }: any) => {
  const record = useRecordContext();
  const count =
    record && Array.isArray(record[source]) ? record[source].length : 0;
  return (
    <NumberField source={source} record={{ ...record, [source]: count }} />
  );
};

export const TagList = () => (
  <List aside={<TagsFilterSidebar />} pagination={<MyPagination />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="UID" source="id" />
      <TextField label="Category" source="category" />
      <TextField label="Name" source="name" />
      <TextField label="Description" source="desc" />
      <LinkedUidsCountField label="Linked UIDs Count" source="linked_uids" />
      <TranslationStatusField label="Translated" source="desc_translated" />
      <DateField label="Created_at" source="created_at" showTime />
      <DateField label="Updated_at" source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const TagCreate = () => (
  <Create>
    <SimpleForm>
      <SelectInput
        label="標籤分類"
        source="category"
        choices={[
          { id: "travel", name: "travel", value: "travel" },
          { id: "study", name: "study", value: "study" },
          { id: "career", name: "career", value: "career" },
          { id: "overseas", name: "overseas", value: "overseas" },
          { id: "business", name: "business", value: "business" },
          { id: "wealth", name: "wealth", value: "wealth" },
        ]}
        validate={required()}
      />
      <TextInput
        source="name"
        validate={[required()]}
        fullWidth
        label="請輸入標籤名稱，一旦產生將無法修改"
      />
      <TextInput label="請輸入標籤描述" source="desc" fullWidth />
    </SimpleForm>
  </Create>
);

// const CustomDescInput = () => {
//   const descTranslated = useWatch({
//     name: "desc_translated.zh-TW",
//   });

//   const { setValue } = useFormContext();

//   useEffect(() => {
//     setValue("desc", descTranslated || "");
//   }, [descTranslated, setValue]);

//   return <TextInput label="description" source="desc" fullWidth />;
// };

const optionRenderer = (choice: any) => `${choice.displayName} (${choice.id})`;

export const TagEdit = () => {
  return (
    <Edit redirect="false">
      <SimpleForm toolbar={<CustomToolbar page="tagEdit" />}>
        <TextInput disabled label="id" source="id" fullWidth />
        <SelectInput
          label="Category"
          source="category"
          choices={[
            { id: "travel", name: "travel", value: "travel" },
            { id: "study", name: "study", value: "study" },
            { id: "career", name: "career", value: "career" },
            { id: "overseas", name: "overseas", value: "overseas" },
            { id: "business", name: "business", value: "business" },
            { id: "wealth", name: "wealth", value: "wealth" },
          ]}
          validate={required()}
          disabled
        />
        <TextInput source="name" validate={[required()]} fullWidth disabled />
        <ReferenceArrayInput
          source="linked_uids"
          reference="users"
          filter={{ isAuthUser: false }}
          fullWidth
        >
          <AutocompleteArrayInput
            optionText={optionRenderer}
            optionValue="id"
            fullWidth
          />
        </ReferenceArrayInput>
        {/* <span
          style={{
            color: "red",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: "0.8em",
            padding: "0",
          }}
        >
          注意:
          編輯description將會自動翻譯下方的Translation欄位，如需做編輯請先重新整理。
        </span> */}
        <TextInput label="description" source="desc" fullWidth />
        <div style={mydiv}>
          <span style={myspan}>AI Translation</span>
        </div>
        {/* <span
          style={{
            color: "red",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontSize: "0.8em",
            padding: "0",
          }}
        >
          如欄位為空白，請重新整理頁面。
        </span> */}
        <TextInput
          label="description (en)"
          source="desc_translated.en"
          fullWidth
          disabled
        />
        <TextInput
          label="description (zh-TW)"
          source="desc_translated.zh-TW"
          fullWidth
          disabled
        />
      </SimpleForm>
    </Edit>
  );
};
