export const flexstart = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  gap: "1rem",
};

export const spacebetween = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: "1rem",
};

export const mydiv = {
  width: "100%",
  textAlign: "center" as const,
  borderBottom: "1px dashed #000",
  lineHeight: "0.1em",
  margin: "10px 0 20px",
};

export const myspan = {
  background: "#fff",
  padding: "0 10px",
};
