import React from "react";
import {
  Datagrid,
  DateField,
  EmailField,
  Identifier,
  List,
  TextField,
} from "react-admin";

export const PendingApprovalList = () => (
  <List>
    <Datagrid
      rowClick={(id: Identifier) => `/users/${id}`}
      bulkActionButtons={false}
    >
      <TextField label="uid" source="id" />
      <EmailField label="email" source="profile.email" />
      <TextField label="name" source="profile.name" />
      <TextField label="role" source="role" />
      <TextField label="onboarding" source="onboarding" />
      <DateField label="updatedAt" source="updated_at" showTime />
      <DateField label="createdAt" source="created_at" showTime />
    </Datagrid>
  </List>
);
