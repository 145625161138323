import React from "react";
import {
  Datagrid,
  List,
  ImageField,
  TextField,
  DateField,
  RichTextField,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
} from "react-admin";
import CustomToolbar from "../CustomToolbar";
import MyPagination from "./MyPagination";
import { RichTextInput } from "ra-input-rich-text";
import ImagePreview from "./ImagePreview";
import { flexstart, spacebetween } from "../layout";
import { Forum } from "../types/_generate/Api";

export const ForumList: React.FC<Forum> = () => (
  <List pagination={<MyPagination />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Forum ID" source="id" />
      <TextField label="Author ID" source="author_id" />
      <TextField label="Author Name" source="author_profile.name" />
      <TextField label="Title" source="title" />
      <TextField label="Category" source="category" />
      <TextField label="Hash Tags" source="hash_tags" />
      <TextField label="Featured Image" source="featured_image" />
      <RichTextField label="Body" source="html_body" stripTags />
      <TextField label="Status" source="status" />
      <DateField label="Created_at" source="created_at" showTime />
      <DateField label="Updated_at" source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const ForumEdit = () => (
  <Edit redirect="false">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput disabled label="id" source="id" fullWidth />
      <TextInput disabled label="created_id" source="create_user" fullWidth />
      <TextInput label="Title" source="title" fullWidth />
      <div style={flexstart}>
        <SelectInput
          label="Status"
          source="status"
          choices={[
            { id: "draft", name: "Draft", value: "draft" },
            { id: "published", name: "Published", value: "published" },
            { id: "reported", name: "Reported", value: "reported" },
          ]}
          validate={required()}
        />
      </div>
      <ArrayInput label="Hash Tags" source="hash_tags">
        <SimpleFormIterator inline>
          <TextInput source="" label="Tag Name" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput label="Author" source="author_profile.name" fullWidth />
      <ImagePreview label="Featured Image" source="featured_image" />
      <TextInput label="Featured Image" source="featured_image" fullWidth />
      <RichTextInput label="Body" source="html_body" />
    </SimpleForm>
  </Edit>
);
