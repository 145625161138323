import React from "react";
import { Avatar } from "@mui/material";
import { useInput, useRecordContext } from "react-admin";

const AvatarField = ({ source }: any) => {
  const { field } = useInput({ source });
  const record = useRecordContext();

  let imgURL = record ? record[source] : "";
  if (field.value) imgURL = field.value;

  return imgURL ? (
    <Avatar
      src={`${imgURL}`}
      sx={{ width: 45, height: 45 }}
      imgProps={{ referrerPolicy: "no-referrer" }}
    />
  ) : null;
};

export default AvatarField;
