import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EmailField,
  List,
  TextField,
  DateField,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  required,
  SelectInput,
  Edit,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import MyPagination from "./MyPagination";
import { UsersFilterSidebar } from "./UsersFilterSidebar";
import CustomToolbar from "../CustomToolbar";
import AvatarField from "./AvatarField";
import UIDNotFoundWarning from "./UIDNotFoundWarning";
import { flexstart, mydiv, myspan, spacebetween } from "../layout";
import { CreateUserDto, UpdateUserDto, Users } from "../types/_generate/Api";

export const UserList: React.FC<Users> = () => (
  <List aside={<UsersFilterSidebar />} pagination={<MyPagination />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="uid" source="id" />
      <EmailField source="email" />
      <BooleanField source="emailVerified" />
      <BooleanField source="disabled" />
      <BooleanField label="authUser" source="isAuthUser" />
      <TextField label="name" source="displayName" />
      <DateField label="lastSignInTime" source="lastSignInTime" showTime />
      <DateField label="creationTime" source="creationTime" showTime />
    </Datagrid>
  </List>
);

export const UserCreate: React.FC<CreateUserDto> = () => (
  <Create>
    <SimpleForm>
      <TextInput source="email" validate={[required()]} fullWidth />
      <PasswordInput source="password" validate={[required()]} fullWidth />
      <TextInput label="Name" source="displayName" fullWidth />
      <AvatarField label="avatar" source="photoURL" />
      <TextInput label="Photo URL" source="photoURL" fullWidth />
      <BooleanInput
        label="Email Verified"
        source="emailVerified"
        defaultChecked={false}
      />
      <BooleanInput label="Disabled" source="disabled" defaultChecked={false} />
    </SimpleForm>
  </Create>
);

export const UserEdit: React.FC<UpdateUserDto> = () => (
  <Edit redirect="false">
    <SimpleForm toolbar={<CustomToolbar />}>
      <div style={mydiv}>
        <span style={myspan}>Firebase Authentication</span>
      </div>
      <TextInput disabled label="uid" source="id" fullWidth />
      <TextInput disabled source="email" validate={[required()]} fullWidth />
      <TextInput disabled label="Name" source="displayName" fullWidth />
      <AvatarField disabled source="photoURL" />
      <TextInput disabled label="Photo URL" source="photoURL" fullWidth />
      <div style={flexstart}>
        <BooleanInput label="Email Verified" source="emailVerified" />
        <BooleanInput label="Disabled" source="disabled" />
      </div>
      <div style={mydiv}>
        <span style={myspan}>Users Collection</span>
      </div>
      <UIDNotFoundWarning source="userData.id" />
      <TextInput disabled label="UID" source="userData.id" fullWidth />
      <div style={flexstart}>
        <SelectInput
          label="Role"
          source="userData.role"
          choices={[
            { id: "user", name: "user", value: "user" },
            { id: "partner", name: "partner", value: "partner" },
            { id: "editor", name: "editor", value: "editor" },
            { id: "admin", name: "admin", value: "admin" },
          ]}
          validate={required()}
        />
        <div style={{ marginBottom: "1em" }}>
          <AvatarField source="userData.profile.avatar" />
        </div>
      </div>
      <TextInput label="Avatar" source="userData.profile.avatar" fullWidth />
      <TextInput label="Name" source="userData.profile.name" fullWidth />
      <div style={spacebetween}>
        <TextInput
          label="Firstname"
          source="userData.profile.firstname"
          fullWidth
        />
        <TextInput
          label="Lastname"
          source="userData.profile.lastname"
          fullWidth
        />
      </div>
      <div style={flexstart}>
        <TextInput label="Phone" source="userData.profile.phone" />
        <DateInput
          label="Birthday"
          source="userData.profile.birthday"
        ></DateInput>
        <TextInput label="Zipcode" source="userData.profile.zipcode" />
      </div>
      <TextInput label="Address" source="userData.profile.address" fullWidth />
      {/* <TextInput label="Country" source="userData.profile.country" fullWidth /> */}
      <TextInput label="Website" source="userData.profile.website" fullWidth />
      <div style={spacebetween}>
        <TextInput
          label="Facebook"
          source="userData.profile.social_media.facebook"
          fullWidth
        />
        <TextInput
          label="Twitter"
          source="userData.profile.social_media.twitter"
          fullWidth
        />
      </div>
      <div style={spacebetween}>
        <TextInput
          label="Instagram"
          source="userData.profile.social_media.instagram"
          fullWidth
        />
        <TextInput
          label="Linkedin"
          source="userData.profile.social_media.linkedin"
          fullWidth
        />
      </div>
      {/* <TextInput
        label="Certificates"
        source="userData.profile.certificates"
        fullWidth
      /> */}

      {/* <ArrayInput label="Skills" source="userData.profile.skills">
        <SimpleFormIterator inline>
          <TextInput source="" label="Tag ID" fullWidth />
          <TextInput source="text" />
          <TextInput source="description" />
          <TextInput source="style" fullWidth />
          <TextInput source="image_url" />
          <AvatarField label="avatar" source="image_url" />
        </SimpleFormIterator>
      </ArrayInput> */}
      <TextInput
        label="Education"
        source="userData.profile.education"
        multiline
        fullWidth
      />
      <TextInput
        label="Experience"
        source="userData.profile.experience"
        multiline
        fullWidth
      />
      <TextInput
        label="Note"
        source="userData.profile.note"
        multiline
        fullWidth
      />
      <div style={flexstart}>
        <BooleanInput label="Onboarding" source="userData.onboarding" />
        <BooleanInput
          label="Profile Published"
          source="userData.profile_published"
        />
        <BooleanInput
          label="Profile Approved"
          source="userData.profile_approved"
        />
      </div>
    </SimpleForm>
  </Edit>
);
