import * as React from "react";
import { forwardRef } from "react";
import { useLogout } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import { LocalStorageKeys } from "./storage";

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => {
    localStorage.removeItem(LocalStorageKeys.token);
    logout();
  };
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Logout
    </MenuItem>
  );
});

export default MyLogoutButton;
