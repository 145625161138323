import React from "react";
import { useRecordContext } from "react-admin";

const UIDNotFoundWarning = ({ source }: any) => {
  const record = useRecordContext();
  const hasUID = record && record.userData && record.userData.id;
  return !hasUID ? (
    <span
      style={{
        color: "red",
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      }}
    >
      No UID found. Please login this user from the app, or delete it to avoid
      data unsynced issue. If it is auth user, no action is needed.
    </span>
  ) : null;
};

export default UIDNotFoundWarning;
