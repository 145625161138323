import React from "react";
import { FilterLiveSearch, FilterList, FilterListItem } from "react-admin";
import { Card, CardContent } from "@mui/material";
import MailIcon from "@mui/icons-material/MailOutline";
import BlockIcon from "@mui/icons-material/Block";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const UsersFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
    <CardContent>
      <FilterLiveSearch />
      <FilterList label="Email Verified" icon={<MailIcon />}>
        <FilterListItem label="Yes" value={{ email_verified: true }} />
        <FilterListItem label="No" value={{ email_verified: false }} />
      </FilterList>
      <FilterList label="Disabled" icon={<BlockIcon />}>
        <FilterListItem label="Yes" value={{ disabled: true }} />
        <FilterListItem label="No" value={{ disabled: false }} />
      </FilterList>
      <FilterList label="Auth User" icon={<AccountCircleIcon />}>
        <FilterListItem label="Yes" value={{ isAuthUser: true }} />
        <FilterListItem label="No" value={{ isAuthUser: false }} />
      </FilterList>
    </CardContent>
  </Card>
);
